import { Component } from '@angular/core';
import { Router, } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { OperationType } from '../finalizar-pagamento.component';
import { MemberService } from 'src/app/core/services/member.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-seja-bem-vindo',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './seja-bem-vindo.component.html',
  styleUrl: './seja-bem-vindo.component.scss'
})
export class SejaBemVindoComponent {

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  imgWelcoming = '/assets/imagens/image_welcome.png';
  clubName = localStorage.getItem('clubName');
  welcomeMessage = '';

  btnGoToLogin = {
    buttonText: 'IR PARA LOGIN',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-4 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  operation: OperationType;
  plan: string; //
  memberLoginData: any;
  isLoggedIn = false;

  constructor(
    private memberService: MemberService,
    private router: Router,
    private paymentDataService: PaymentDataService,
    private authService: AuthService
  ) {
    this.operation = this.paymentDataService.getOperationType();

    if (this.operation == null || this.operation == undefined) {
      this.router.navigate(['/login']);
    }
    this.authService.isLoggedIn.subscribe(status => {
      this.isLoggedIn = status;
    });

    this.setMessageByOperation()
    this.plan = this.memberService.getMemberPlan()?.planName || '';
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  setMessageByOperation(): void {
    if (this.isLoggedIn) this.btnGoToLogin.buttonText = 'IR PARA DASHBOARD';
    switch (this.operation) {
      case OperationType.NEW:
        
        this.welcomeMessage = `Parabéns!<br>Agora você é um sócio ${this.clubName}`;
        break;
      case OperationType.UPGRADE:
        this.welcomeMessage = `Parabéns!<br>Você agora faz parte do plano ${this.plan}!`;
        break;
      case OperationType.RENEW:
      case OperationType.EARLY_RENEW:
        this.welcomeMessage = 'Parabéns!<br>Sua renovação foi realizada com sucesso!';
        break;
      case OperationType.NEWLOGGED:
        this.welcomeMessage = `Parabéns!<br>Agora você é um sócio ${this.clubName}`;
        this.btnGoToLogin.buttonText = 'IR PARA DASHBOARD';
        break;
      default:
        break;
    }
  }
}
