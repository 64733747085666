import { Component, input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PlanService } from 'src/app/core/services/plan.service';
import { MyPlanCardComponent } from 'src/app/shared/components/my-plan-card/my-plan-card.component';
import { Plan, PlanDashboardTO, PlanUpgradeRenewal, } from 'src/app/shared/models/planModel';
import { UpgradeCardComponent } from "../../shared/components/upgrade-card/upgrade-card.component";
import {  InvoiceModelPublic } from 'src/app/shared/models/invoiceModel';
import { CommonModule } from '@angular/common';
import { ViewPlanCardComponent } from 'src/app/shared/components/view-plan-card/view-plan-card.component';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InvoiceTableComponent } from 'src/app/shared/components/invoice-table/invoice-table.component';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { MemberService } from 'src/app/core/services/member.service';
import { MemberShipCardsDependentsModel } from 'src/app/shared/models/memberModel';

@Component({
  selector: 'app-meu-plano',
  standalone: true,
  imports: [CommonModule, MyPlanCardComponent, RouterLink, UpgradeCardComponent, InvoiceTableComponent, ViewPlanCardComponent, AppButtonComponent],
  templateUrl: './meu-plano.component.html',
  styleUrl: './meu-plano.component.scss'
})
export class MeuPlanoComponent implements OnInit {

  plan: PlanDashboardTO;
  invoiceList: InvoiceModelPublic[];
  memberShipCardsDependents: MemberShipCardsDependentsModel[] = [];
  memberShipCard: any;

  public styles: { [key: string]: string };
  carteirinhaImg = localStorage.getItem('imageLogo');

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };
  member: UserModel;
  plansUpgrade: PlanUpgradeRenewal[] = [];
  showUpgradeCard = false;

  public trigger$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private planService: PlanService,
    private location: Location,
    private memberService: MemberService,
    private userService: UserService
  ) {
    this.member = this.userService.currentUserValue as UserModel;    
  }

  ngOnInit() {
    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };
    this.getPlanbyId();
    this.getMembePlanDashboardData();
   
    this.memberService.getMemberShipCardDashboardData(this.member.id).subscribe(res =>{
      this.memberShipCard = res;

    })

    this.memberService.getMemberDashboardData(this.member.id).subscribe((dataMember) => {
      if (dataMember) {
        this.memberService.getMemberShipCardsDependentsDashboardData(dataMember.id).subscribe((res)=> {
          this.memberShipCardsDependents = res;
        });
      }
    });

    const dataMember = localStorage.getItem('data-member');
    if(dataMember){
      this.memberService.getMemberShipCardsDependentsDashboardData(JSON.parse(dataMember).id).subscribe(res=>{
        this.memberShipCardsDependents = res || [];
      })
    }

  }

  getMembePlanDashboardData(){
    this.memberService.getMembePlanDashboardData(this.member.id).subscribe((res) => {
      this.plan = res;
           

      if (this.plan) {
        this.memberService.getMembeInvoiceDashboardData(this.plan.id).subscribe((invoices) => {

         invoices.forEach(invoice => {
            invoice.referenceMonthSort = new Date(parseInt(invoice.refYear), parseInt(invoice.refMonth)- 1);
            invoice.refMonth = invoice.refMonth?.toString()?.padStart(2, '0');
          });
          invoices.sort((a, b) => new Date(b.referenceMonthSort).getTime() - new Date(a.referenceMonthSort).getTime());
          this.invoiceList = invoices;
        });
      }
    });
  }

  getPlanbyId() {
    let planId = this.member?.memberPlans ? this.member.memberPlans[0].planId as string : '' ;
    this.planService.getPlanById(planId).subscribe((plan: Plan) => {
      this.plansUpgrade = plan.planUpgrades;
      this.showUpgradeCard = this.plansUpgrade.length > 0 && this.member?.memberSituation?.name === "Adimplente" && (this.member?.memberPlans && this.member?.memberPlans[0]?.memberPlanStatusId === MemberPlanStatusId.ATIVO) as boolean; 
    });
  }



  back() {
    this.location.back();
  }

  showCarteirinha() {
    this.trigger$.next(true);
  }

}

enum MemberPlanStatusId {
  ATIVO = '1dc1bc30-b0fd-45de-bd3c-72f78cafb8f4'
}