import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { GameService } from 'src/app/core/services/games.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Page } from 'src/app/shared/models/Page';
import { FootballMatchModel } from 'src/app/shared/models/footballMatchModel';

@Component({
  selector: 'app-section-proximo-confronto',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent
  ],
  templateUrl: './section-proximo-confronto.component.html',
  styleUrl: './section-proximo-confronto.component.scss'
})
export class SectionProximoConfrontoComponent {

  buttonOptions = {
    primaryColor: '',
    secondaryColor: '',
    buttonText: 'COMPRE AGORA SEUS INGRESSOS',
    buttonTextColor: '',
    borderRadius: '25px',
    buttonBorderWidth: '',
    buttonBorderColor: '',
    buttonSize: 'btn btn-md'
  };

  isMobile = window.innerWidth < 768;
  showIndicators = false;
  logoUrl: string = '';

  matchesArr: Matches[] = [];
  styles: { [key: string]: string } = {};
  
  constructor(
    private gameService: GameService,
    private commonService: CommonService,
    private headerService: HeaderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getNexMatchesStyle();
    this.getLogo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
    this.showIndicators = this.matchesArr.length > 1;
  }

  getLogo() {
    this.headerService.getHeader().subscribe({
      next: (res: any) => {
        if(res.logoPath) {
          this.logoUrl =  res.logoPath;
        } else {
          this.logoUrl = this.commonService.getImageStr(res.imageLogo.id);
        }
        this.getNextMatches();
      }
    });
  }

  getNextMatches(): void {
    this.gameService.getAllGamesPublic().subscribe(
      (res: Page<FootballMatchModel>) => {
        this.setMatchesArr(res.content);
      }
    );
  }

  private setMatchesArr(matchesArr: FootballMatchModel[]): void {
    if (!matchesArr.length) {
      return;
    }
    
    matchesArr.forEach((confrontation: FootballMatchModel) => {
      const match = {
        title: 'Próximo confronto',
        subTitle: 'Adquira seus ingressos para o nosso próximo confronto',
        team1: {
          name: confrontation.soccerTeamName,
          src: this.logoUrl
        },
        team2: {
          name: confrontation.adversary.name,
          src: this.commonService.getImageStr(confrontation.adversary.document.id)
        },
        championship: confrontation.championship.title,
        local: confrontation.location.name,
        data: this.commonService.getInvertedDate(confrontation.gameDate),
        hora: confrontation.gameTime
      }

      this.matchesArr.push(match);
    });

    this.showIndicators = this.matchesArr.length > 1;
  }

  getNexMatchesStyle() {
    this.gameService.getNextMatches().subscribe({
      next: (response: any) => {
        let nextMatchesResponse = response;
        this.styles = {
          '--background-color': nextMatchesResponse?.backgroundColor,
          '--background-image': nextMatchesResponse?.backgroundIsImage ? `url(${this.getImageUrl(nextMatchesResponse)})`: 'none',
          '--background-is-image': nextMatchesResponse?.backgroundIsImage,
          '--title-font-size': this.commonService.getRemFontSize(nextMatchesResponse?.titleFontSize, this.isMobile),
          '--title-color': nextMatchesResponse?.titleColor,
          '--subtitle-font-size':this.commonService.getRemFontSize(nextMatchesResponse?.subtitleFontSize, this.isMobile),
          '--subtitle-color': nextMatchesResponse?.subtitleColor,
          '--club-name-font-size': this.commonService.getRemFontSize(nextMatchesResponse?.clubNameFontSize, this.isMobile),
          '--club-name-color': nextMatchesResponse?.clubNameColor,
          '--match-details-font-size': this.commonService.getRemFontSize(nextMatchesResponse?.matchDetailsFontSize, this.isMobile),
          '--match-details-color': nextMatchesResponse?.matchDetailsColor,
          '--date-time-font-size': this.commonService.getRemFontSize(nextMatchesResponse?.dateTimeFontSize, this.isMobile),
          '--date-time-color': nextMatchesResponse?.dateTimeColor,
          '--show-section': nextMatchesResponse?.showSection? 'block': 'none',
          sectionTitle: nextMatchesResponse?.sectionTitle,
          subTitle: nextMatchesResponse?.subtitleText,
          showSubtitle: nextMatchesResponse?.showSubtitle,
          '--side-buttons-color': nextMatchesResponse?.sideButtonsColor,
          '--side-buttons-border-color': nextMatchesResponse?.sideButtonsBorder ?  nextMatchesResponse?.sideButtonsBorderColor : 'none',
          '--side-buttons-icon-color': nextMatchesResponse?.sideButtonsIconColor == 'true' ? 'invert(1)':'invert(0)',
          '--carousel-indicators-color': nextMatchesResponse?.mainButtonColor,
        }
        this.buttonOptions.primaryColor = nextMatchesResponse?.mainButtonColor;
        this.buttonOptions.buttonTextColor = nextMatchesResponse?.mainButtonTextColor;
        this.buttonOptions.buttonBorderColor = nextMatchesResponse?.mainButtonBorderColor;
        this.buttonOptions.buttonBorderWidth = nextMatchesResponse?.mainButtonBorder ? '1px' : 'none';
        
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    });
  }

  getImageUrl(nextMatchesResponse: any) {
    if (nextMatchesResponse.defaultStyle) {
      return nextMatchesResponse.backgroundImageUrl;
    }
    return this.commonService.getImageStr(nextMatchesResponse?.document.id);
  }

  getHour(hour: string, minute: string): string {
    let h = ("0" + parseInt(hour)).slice(-2);
    let m = ("0" + parseInt(minute)).slice(-2);
    return `${h}:${m}`;
  }

  getDate(date: string): string {
    let day = ("0" + date[2]).slice(-2);
    let month = ("0" + date[1]).slice(-2);
    let year = date[0];
    return `${day}/${month}/${year}`;
  }

  goToRegister(){
    this.router.navigate(['/cadastro-socio']);
  }

}

interface Matches {
  title: string,
  subTitle: string,
  team1: {
    name: string
    src: string,
  },
  team2: {
    name: string
    src: string
  },
  local: string,
  data: string,
  hora: string,
  championship: string
}
