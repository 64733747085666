@if (styles) {
<section class="main-section d-flex  align-items-center" [ngStyle]="styles">
  <div class="container justify-content-center d-flex text-center">
    <div class="row">
    <div class="col-md-10 offset-md-1">
      <h1 class="pb-3 ">
        <span class="prefixo">{{componentData.prefixo}}</span>
        <span *ngIf="sectionMain?.hasCounter" class="specialCharacter">{{componentData.specialCharacter}}</span>
        <span *ngIf="sectionMain?.hasCounter" class="highlight">{{componentData.highlight}}</span>
        <span class="sufixo">{{componentData.sufixo}}</span>
      </h1>
      <p *ngIf="sectionMain?.hasSubtitle" class="pb-5 px-4 subtitle">{{componentData.subtitle}}</p>
      <app-button *ngIf="sectionMain?.hasJoinPlanButton" 
        [buttonOptions]="buttonOptions" 
        routerLink="/cadastro-socio">
      </app-button>
    </div>
  </div>
  </div>
</section>
}