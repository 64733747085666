import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, input } from '@angular/core';
import { FullScreenDirective, FullscreenTransition } from '../../directives/full-screen.directive';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CustomButtonDto } from '../app-button/app-button-dto';
import { QRCodeModule } from 'angularx-qrcode';
import { MemberShipCardsDependentsModel } from '../../models/memberModel';

@Component({
  selector: 'app-view-plan-card',
  standalone: true,
  imports: [CommonModule, FullScreenDirective, AppButtonComponent, QRCodeModule],
  templateUrl: './view-plan-card.component.html',
  styleUrl: './view-plan-card.component.scss'
})
export class ViewPlanCardComponent implements OnInit {
  @Input() trigger: BehaviorSubject<boolean>;
  memberShipCard = input<any>();

  public styles: { [key: string]: string };

  carteirinhaImg = localStorage.getItem('imageLogo'); // TODO: Alterar caminho da image mock 
  qrCodeImg = '/assets/imagens/qr-code.png'; // TODO: Alterar caminho da image mock 


  btnBackOptions = {
    buttonText: 'Voltar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-secondary',
  } as CustomButtonDto;

  btnShowCodeOptions = {
    buttonText: 'Visualizar Código',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-md-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  } as CustomButtonDto;

  btnShowCardOptions = {
    buttonText: 'Visualizar Carteirinha',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-md-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  } as CustomButtonDto;

  @ViewChild(FullScreenDirective, { static: true }) fullscreen!: FullScreenDirective;
  private _destroy$ = new Subject<void>();

  isFullscreen = false;
  cardView = true;

  ngOnInit(): void {
    this.trigger
    .pipe(takeUntil(this._destroy$))
    .subscribe((value: boolean) => {
      if (value) {
        this.cardView = true;
        this.fullscreen.toggle();
      }
    });

    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };

    // this.qrCodeData = this.memberShipCard()?.cardNumber as string;
    // this.qrCodeData = (cardFormated?.cardNumber)?.toString();
  }

  onFullscreenChange(event: FullscreenTransition) {
    this.isFullscreen = event.isFullscreen;
  }

  toggleFullscreen() {
    this.fullscreen.toggle();
  }

  switchView() {
    this.cardView = !this.cardView;
    
  }

  back() {
    this.fullscreen.exit();
  }

  ngOnDestroy(): void {
    // Emit a signal to unsubscribe from the observable
    this._destroy$.next();
    this._destroy$.complete();
  }
}
