import { AfterViewInit, Component, Input, OnDestroy, OnInit, input, model } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { PaymentType } from '../../models/paymentData';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CommonModule } from '@angular/common';
import { InputCopyToClipboardComponent } from '../input-copy-to-clipboard/input-copy-to-clipboard.component';
import { AppSelectInputComponent, SelectModel } from '../app-select-input/app-select-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppInputCustomComponent, InputMaskTypes } from '../app-input-custom/app-input-custom.component';
import { AppToggleButtonComponent } from '../app-button-group/app-toggle-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { DateMode } from 'src/app/core/services/common.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { ChargerService } from 'src/app/core/services/charger.service';
import { PaymentMethodsService } from 'src/app/core/services/payment-methods.service';
import { PaymentMethodsCheckboxModel } from '../../models/paymentMethodsModel';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { HttpParams } from '@angular/common/http';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';

declare var bootstrap: any;

@Component({
  selector: 'app-payment-invoice-modal',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, InputCopyToClipboardComponent, AppSelectInputComponent, ReactiveFormsModule,
    AppInputCustomComponent, AppInputComponent, AppToggleButtonComponent, CustomCurrencyPipe],
  templateUrl: './payment-invoice-modal.component.html',
  styleUrl: './payment-invoice-modal.component.scss'
})
export class PaymentInvoiceModalComponent implements OnInit, OnDestroy {

  canChekPix = true;

  modalId = input.required<string>();
  backdropType = input<string>();
  paymentType = input<PaymentType>();
  paymentInvoiceModalData = input.required<PaymentInvoiceModalData>();
  InputMaskTypesEnum = InputMaskTypes;
  InputTypeEnum = InputType;
  DateMode = DateMode;
  numberOfInstallmentsOptions: SelectModel[] = [];
  
  showModal = false;

  creditCardformGroup = new FormGroup({
    paymentMethodId: new FormControl('', Validators.required),
    cardNumber: new FormControl('', Validators.required),
    cardCvv: new FormControl('', Validators.required),
    cardExpirationDate: new FormControl('', Validators.required),
    cardHolderName: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.required),
  });

  PaymentType = PaymentType;
  confirmAction = model(false);
  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    fontSize: 14,
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Confirmar Pagamento',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 px-sm-5 fs-sm-xsmall btn-primary',
    fontSize: 14,
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  paymentMethods: PaymentMethodsCheckboxModel[] = []

  qrCodeCreateAt: string;
  qrCodeExpirationTime: number = 180; // Tempo de expiração em segundos (por exemplo, 5 minutos)
  timeLeft: number;
  timeLeftFormatted: string;
  intervalSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private paymentDataService: PaymentDataService,
    private chargerService: ChargerService,
    private paymentMethodsService: PaymentMethodsService,
    private alertService: AlertService,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === this.modalId()) {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            const modal = new bootstrap.Modal(modalElement);
            this.showModal = false;
            this.canChekPix = true;
            this.confirmAction.set(false);
            this.getPaymentMethods();

            modal.show();
          } else if (state.action === 'close') {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              this.canChekPix = false;
              modal.hide();
            }
          }
        }
      }
    });
  }

  validateCpfNumber(cpf:any) {
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false; // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    }
  
    let soma = 0;
    let resto;
  
    // Valida o primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
  
    soma = 0;
  
    // Valida o segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }
  
    return true;
  } 

  onConfirm() {
    if(this.creditCardformGroup.get('cardExpirationDate')?.value != '' && this.creditCardformGroup.get('cardExpirationDate')?.value != null) {
      const expirationDate = (this.creditCardformGroup.get('cardExpirationDate')?.value as string).split('-');
      this.creditCardformGroup.get('cardExpirationDate')?.setValue(expirationDate[1] + '/' + expirationDate[0]);
    }
    this.chargerService.payInvoice(this.creditCardformGroup.value, this.paymentInvoiceModalData().invoiceId as string).subscribe(
      (data: any) => {
        if (this.paymentType() === PaymentType.CREDITO) {
          this.alertService.showAlert(AlertType.SUCCESS, 'Pagamento efetuado com sucesso!');
          this.confirmAction.set(true);
          this.modalService.close(this.modalId());
        } else {
          this.paymentInvoiceModalData().invoiceCodeImg = data.qrCodeImageUrl;
          this.paymentInvoiceModalData().invoiceCode = data.qrCodeData;
          this.showModal = true;
          this.checkPixPayment()
        }
      },
      (error) => {
        setTimeout(() => {
          if (this.paymentType() === PaymentType.CREDITO) {
            this.alertService.showAlert(AlertType.DANGER, error.error.message);
          }else{
            this.alertService.showAlert(AlertType.DANGER, 'Erro ao gerar os dados do PIX');
          }

          this.onCancel();
        }, 500);
       
      }
    ); 
  }

  checkPixPayment(): void {
    let params = new HttpParams();
    params = params.append('showLoader', 'false');
    this.invoiceService.getInvoiceById(this.paymentInvoiceModalData().invoiceId as string, params).subscribe((data) => {
      this.qrCodeCreateAt = data.createdAt as string;
     this.getExpirationQrCode();
      if (data.invoicePaymentStatus != '7859ffd3-9390-4d25-89a6-8f27533bb37e') {
        if (this.canChekPix) {
          setTimeout(() => {
            this.checkPixPayment();
          }, 3000);
        }
      } else {
        this.alertService.showAlert(AlertType.SUCCESS, 'Pagamento efetuado com sucesso!');
        this.confirmAction.set(true);
        this.modalService.close(this.modalId());
      }
    });
  }

  getExpirationQrCode() {
    const timeZone = 'America/Sao_Paulo';

    // Converte a data de criação do QR Code para o fuso horário do Brasil
    const createdAt = new Date(this.qrCodeCreateAt).toLocaleString('en-US', { timeZone });
    const createdAtDate = new Date(createdAt);
    console.log('createdAtDate', createdAtDate);


    // Obtém a data e hora atual no fuso horário do Brasil
    const now = new Date().toLocaleString('en-US', { timeZone });
    const nowDate = new Date(now);

    const elapsedTime = Math.floor((nowDate.getTime() - createdAtDate.getTime()) / 1000);
    this.timeLeft = this.qrCodeExpirationTime - elapsedTime;
    console.log('timeLeft', this.timeLeft);
    this.timeLeftFormatted = new Date(this.timeLeft * 1000).toISOString().substr(14, 5);
    console.log('timeLeftFormatted', this.timeLeftFormatted);
    
    // while (this.timeLeft < 0) {
    //   setTimeout(() => {
    //     this.timeLeft = this.timeLeft - 1;
    //     this.timeLeftFormatted = new Date(this.timeLeft * 1000).toISOString().substr(14, 5);
    //     console.log('timeLeftFormatted', this.timeLeftFormatted);

    //   }, 1000);
    // }

  }


  getPaymentMethods() {
    this.paymentMethodsService.getPaymentMethodsCheckBox().subscribe((data) => {
      this.paymentMethods = data;
      this.creditCardformGroup.get('paymentMethodId')?.setValue(this.paymentMethods.find((x) => x.name == this.paymentType())?.id as string);
      if (this.paymentType() === PaymentType.PIX) {

        this.onConfirm();
      }
    });
  }


  onCancel() {
    this.canChekPix = false;
    this.confirmAction.set(false);
    this.modalService.close(this.modalId());
  }

  ngOnDestroy(): void {
    console.log('destroy');
    
    this.canChekPix = false;
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

}

export interface PaymentInvoiceModalData {
  invoiceId?: string;
  invoiceNumber?: string;
  invoiceCode?: string;
  invoiceCodeImg?: string;
  invoiceAmount?: number;
  creditCardOptions?: SelectModel[];
}
