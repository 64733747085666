import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }
  private modalSubject = new Subject<any>();

  modalState$ = this.modalSubject.asObservable();

  open(modalId: string) {
    this.modalSubject.next({ action: 'open', id: modalId });
  }

  close(modalId: string) {
    this.modalSubject.next({ action: 'close', id: modalId });
  }
}
