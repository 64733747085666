
<div #fullscreen
    [fullscreen]="fullscreen" (change)="onFullscreenChange($event)">
    <div class="justify-content-center h-100 p-md-5" [ngClass]="{'d-none': !isFullscreen, 'row': isFullscreen}">

        <!--  Visão desktop: Mostra do md pra cima -->
        <app-button id="back-desktop" class="d-none d-md-block text-end align-content-center p-3"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="btnBackOptions"
            (buttonClick)="back()">
        </app-button>

        <div class="m-5 carteirinha d-grid justify-content-center">
            <!-- <img *ngIf="cardView" class="img-fluid" [src]="carteirinhaImg" alt="Carteitinha"> -->
            <div *ngIf="cardView" class="memberShipCard" [ngStyle]="styles">
                <div class="row m-0">
                    <div class="col-9 d-flex align-items-start flex-column mb-2">
                        <div class="mb-auto p-2 d-flex" >
                            <img class="img-fluid imgCarteirinha mt-1" [src]="carteirinhaImg" alt="Carteitinha">
                            <h1 class="align-items-center mt-4" style="font-size: 1.2rem;">N°: {{memberShipCard()?.cardNumber}}</h1>
                        </div>
                        <div class="p-2">
                            <h1 class="align-items-end" style="font-size: 1.5rem;">{{memberShipCard()?.member?.name}}</h1>
                        </div>
                    </div>
                    <div class="col-3 card-lateral">
                    </div>
                </div>
             </div> 
             <qrcode *ngIf="!cardView" #qrCode [qrdata]="memberShipCard()?.cardNumber" [width]="300" elementType="svg"></qrcode>
        </div>

        <!-- Visão mobile: Escondido do md pra cima -->
        <div class="d-block d-md-none col-6 col-md-12 p-3 text-center btn-back-mobile">
            <app-button id="back-mobile"
                [isIcon]="true" [left]="true"
                [iconName]="'bi bi-arrow-left'"
                [buttonOptions]="btnBackOptions"
                (buttonClick)="back()">
            </app-button>
        </div>

        <div class="p-3 text-center col-6 col-md-12 btn-show-code">
            <app-button id="show-code-card"
                [buttonOptions]="cardView ? btnShowCodeOptions : btnShowCardOptions"
                (buttonClick)="switchView()">
            </app-button>
        </div>
    </div>
</div>
