@if(data().length > 0) {
<div class="app-cards-palpites">
    <h4 class="main-subtitle" [style.color]="labelColor">
        {{ title() }}
    </h4>
    <div class="cards">
        @for (item of data(); track $index) {
        <div class="bg-white card">
            @if (item.teams.length > 0) {
            <div class="text-center my-2">
                <strong>{{ item.championship }}</strong>
            </div>
            <div class="times">
                <div class="time">
                    <img [src]="item.teams[0].logo" />
                    <p>{{ teamName }}</p>
                </div>
                <p class="x">X</p>
                <div class="time">
                    <img [src]="item.teams[1].logo" />
                    <p>{{ item.teams[1].nome }}</p>
                </div>
            </div>
            <div class="text-center">
                <p>{{ item.date }}</p>
            </div>
            }
            @if (item.prediction) {
            <h3>Palpite</h3>
            <div class="prediction">
                <p>{{ item.prediction.firstTeam }}</p>
                <span>-</span>
                <p>{{ item.prediction.secondTeam }}</p>
            </div>
            }
            @if (item.result.firstTeam != null) {
            <h3>Resultado</h3>
            <div class="prediction result">
                <p>{{ item.result.firstTeam }}</p>
                <span>-</span>
                <p>{{ item.result.secondTeam }}</p>
            </div>
            }
            @else if(item.canGuess) {
            <div class="content">
                @if(!item.prediction) {
                <p>Dê o seu palpite do placar do jogo, se você acertar garante pontos para o seu ranking.</p>
                }
                @if(item.prediction && !item.result) {
                <p>Aguarde o final do jogo para receber o resultado.</p>
                }
            </div>
            }
            @if(item.points != '' && item.points != null) {
            <div class="points" [ngStyle]="{'flex-direction': item.winner ? 'column': 'row'}">
                @if(item.points != '') {
                @if(item.winner) {
                <p class="winner">Parabéns! Você ganhou</p>
                <div class="point">
                    <p>{{ item.points }} pontos</p>
                </div>
                }
                <div class="point">
                    @if(!item.winner && item.result.firstTeam == null) {
                    <span>Vale: </span>
                    <p>{{ item.points }} pontos</p>
                    }

                </div>
                }
            </div>
            }
            @if(!item.prediction && !item.result.firstTeam && item.canGuess){
            <app-button class="app-button button pt-4" [style]="'--bs-btn-padding-x: 1.4em; width: 100%;'"
                [buttonOptions]="predictionBtn" (buttonClick)="btnAction(item)">
            </app-button>
            }

        </div>
        }
    </div>
    <div class="mt-5">
        <p>*Os palpites só podem ser feitos até 2 horas antes do início da partida</p>
    </div>
</div>
}
