import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseService {

  

  private env = environment;


  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getAllPartners() {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/partners`);
  }

  getParnersStyles() {
    return this.get(`${this.env.publicApiSocioUrl}v1/socio/benefit-sections`);
  }

}
