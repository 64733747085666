import { Component, ElementRef, EventEmitter, input, OnInit, Output, ViewChild } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { PlanDashboardTO } from '../../models/planModel';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ViewPlanCardComponent } from '../view-plan-card/view-plan-card.component';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from '../../models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { CustomCurrencyPipe } from "../../pipe/custom-currency.pipe";
import { OperationType } from 'src/app/features/finalizar-pagamento/finalizar-pagamento.component';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';

@Component({
  selector: 'app-my-plan-card',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink, ViewPlanCardComponent, CustomCurrencyPipe],
  templateUrl: './my-plan-card.component.html',
  styleUrls: ['./my-plan-card.component.scss']
})
export class MyPlanCardComponent implements OnInit {
  plan = input.required<PlanDashboardTO>();
  //memberShipCard = input<any>();
  memberShipCard: any;
  detailedMode = input<boolean>(false);
  showDowngrade = input<boolean>(false);

  public styles: { [key: string]: string };

  carteirinhaImg = localStorage.getItem('imageLogo');
  public trigger$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  docsOptions = {
    buttonText: 'Enviar documentação',
    buttonSize: 'btn btn-md px-3 py-1 float-right my-2 btn-primary',
    borderRadius: '25px'
  };

  btnPlanDetailsOptions = {
    buttonText: 'Ver detalhes do plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnRenewPlanOptions = {
    buttonText: 'Renovação',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEarlyRenewPlanOptions = {
    buttonText: 'Renovação Antecipada',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEditPlanOptions = {
    buttonText: 'Editar Plano',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnOtherPlansOptions = {
    buttonText: 'Outros planos',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };

  btnEnvDocumentsOptions = {
    buttonText: 'Enviar Documentos',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
  };
  userInfo: UserModel;
  currentPlan!: any;
  memberSituations: any[] = [];
  memberSituation: any;
  memberCanceled: boolean = false;
  notRenwed: boolean = false;

  constructor(
    private memberService: MemberService,
    private userService: UserService,
    private router: Router,
    private paymentDataService: PaymentDataService
  ) { }

  ngOnInit(): void {
    this.styles = {
      '--primaryColor': localStorage.getItem('primaryColor') as string,
    };

    this.memberService.getMemberShipCardDashboardData(this.plan().member.id).subscribe(res => {
      this.memberShipCard = res;
    })

    this.userService.currentUser.subscribe(user => {
      this.userInfo = user as UserModel;
      this.memberCanceled = this.userInfo?.memberSituation?.name == 'Cancelado';
      this.notRenwed = this.userInfo?.memberSituation?.name == 'Não Renovado' || this.userInfo?.memberSituation?.name == 'Lead (Ex-sócio)';

    });
    if (this.userInfo) {
      this.memberService.getCurrentPlan(this.userInfo.id).subscribe((currentPlan: any) => {
        this.currentPlan = currentPlan;

      })
    }
    this.onGetMemberSituations();

  }


  onGetMemberSituations() {
    this.memberService.getMemberSituations().subscribe({
      next: (response) => {
        this.memberSituations = response.content;
      }
    })
  }

  setHeightCard(height: any) {
    const planCard = document.getElementById('planCard');
    if (planCard) {
      planCard.style.height = `${height}px`;
    }
  }

  showCarteirinha() {
    this.trigger$.next(true);
  }

  checkDaysEndData() {
    let planEndDate = new Date(this.currentPlan.planEndDate);
    let currentDate = new Date();
    let diff = Math.abs(planEndDate.getTime() - currentDate.getTime());
    let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  checkMemberSituation(buttomAction: string) {
    let adimplente = this.memberSituations.find((situation: any) => situation.name == 'Adimplente')?.id;
    let inadimplente = this.memberSituations.find((situation: any) => situation.name == 'Inadimplente')?.id;
    let mensalPlan = this.currentPlan?.periodicity == 'MENSAL';
    let acceptedRenewal = this.currentPlan?.anticipationRenewal;

    switch (buttomAction) {
      case 'cancel':
        if (this.memberSituation == adimplente || this.memberSituation == inadimplente || !this.memberCanceled) return true;
        return false;

      case 'upgrade':
        if (this.memberSituation == adimplente) return true;
        return false;

      case 'renew':
        if (!acceptedRenewal) return false;
        if (mensalPlan) return false;
        if (this.memberCanceled) return false;
        let daysEndData = this.checkDaysEndData();
        if (this.notRenwed) return true;
        return false;

      case 'early_renew':
        if (!acceptedRenewal) return false;
        if (mensalPlan) return false;
        if (this.memberCanceled) return false;
        let daysEndDataEarly = this.checkDaysEndData();
        if (daysEndDataEarly > 30) return true;
        return false;

      default:
        return false;
    }
  }

  goToRenewPlan(operationType: OperationType) {
    this.paymentDataService.setOperationType(operationType);
    this.router.navigate(['/renovar-plano']);
  }

  get operationType(): any {
    return OperationType
  }
}

