import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AppCardsResgatarComponent } from 'src/app/shared/components/app-cards-resgatar/app-cards-resgatar.component';
import { CardsResgatar } from 'src/app/shared/models/cardsResgatar';
import { PartnerService } from 'src/app/core/services/partner.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-parcerias',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, ReactiveFormsModule, AppCardsResgatarComponent],
  templateUrl: './parcerias.component.html',
  styleUrl: './parcerias.component.scss'
})
export class ParceriasComponent implements OnInit {
  labelColor = 'white';
  itemsFiltered: any;
  itemsInvalidFiltered: any;
  filterGroup = new FormGroup({
    filterControl: new FormControl<string>('')
  })

  buttonBackOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  titleInvalid = "Cupons Utilizados";
  partners: any;

  items: CardsResgatar[] = [];

  itemsInvalid: CardsResgatar[] = [];

  constructor(
    private partnerService: PartnerService,
    private commonService: CommonService,
    private location: Location
  ) { }

  filter(){
    let textFilter = this.filterGroup.get('filterControl')?.value;
    if (textFilter && textFilter.length >= 2) {
      this.itemsFiltered = this.items.filter(a => a.title.toLowerCase().includes(textFilter.toLowerCase()))
      this.itemsInvalidFiltered = this.itemsInvalid.filter(a => a.title.toLowerCase().includes(textFilter.toLowerCase()))
    } else {
      this.itemsFiltered = this.items;
      this.itemsInvalidFiltered = this.itemsInvalid;
    }
  }

  ngOnInit(): void {
    this.getPartners()
  }

  getPartners() {
    this.partnerService.getAllPartners().subscribe((partners: any) => {
      this.partners = partners.content;
      this.partners.forEach((partner: any) => {
        partner.src = this.commonService.getImageStr(partner.document.id);
        // partner.points = 500;
       // partner.coupon = 'CUPOM500';
      });
      this.setItemsFiltered(this.partners);
    });

  }

  setItemsFiltered(partners: any) {
    this.items = partners.map((item: any) => {
      return {
        topIcon: false,
        image: item.src,
        title: item.partnerName,
        content: item.description,
        valid: 'Válido até 24/04',
        point: item.points || '',
        cupom: item.cupom,
        value: item.isPercentualDiscount ? item.discount + '%' : 'R$ ' + item.discount.toFixed(2).replace('.', ','),
        inactiveItem: !item.isActive,
        inactiveButton: false,
        link: item.link
      }
    });
    this.itemsFiltered = this.items;
    this.itemsInvalidFiltered = this.itemsInvalid;
  }

    
  back() {
    this.location.back();
  }


}
