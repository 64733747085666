import { Component, input, OnInit } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { Router } from '@angular/router';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { OperationType } from 'src/app/features/finalizar-pagamento/finalizar-pagamento.component';
import { PlanDashboardTO } from '../../models/planModel';

@Component({
  selector: 'app-upgrade-card',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './upgrade-card.component.html',
  styleUrl: './upgrade-card.component.scss'
})
export class UpgradeCardComponent implements OnInit {

  plan = input.required<PlanDashboardTO>();

  gamerImg = '/assets/imagens/gamer-standup.png';
  router: Router;
  constructor(
    router: Router,
    private paymentDataService: PaymentDataService
  ) {
    this.router = router;
  }

  ngOnInit(){
    console.log(`RYANZEIRA`,this.plan());
  }

  

  btnUpgradeOptions = {
    buttonText: 'Fazer Upgrade',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-small py-1 px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  btnUpgradeClick() {
    if(this.plan().recurrence === true) {
    this.paymentDataService.setOperationType(OperationType.UPGRADE_RECURRENCE);
    this.router.navigate(['/upgrade-plano']);
  }else {
    this.paymentDataService.setOperationType(OperationType.UPGRADE);
    this.router.navigate(['/upgrade-plano']);
  }
  }




}
