import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { InvoiceModel } from 'src/app/shared/models/invoiceModel';
import { PlanDashboardTO } from 'src/app/shared/models/planModel';
import { UserModel } from 'src/app/shared/models/userModel';
import { MemberFinalScoreModel } from 'src/app/shared/models/member-final-score';
import { MyPlanCardComponent } from "../../shared/components/my-plan-card/my-plan-card.component";
import { ModalService } from 'src/app/core/services/modal.service';
import { CreditCardInfoModel, PaymentType } from 'src/app/shared/models/paymentData';
import { CommonService } from 'src/app/core/services/common.service';
import { InvoiceTableComponent } from 'src/app/shared/components/invoice-table/invoice-table.component';
import { CarouselPlansComponent } from 'src/app/shared/components/carousel-plans/carousel-plans.component';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { MemberService } from 'src/app/core/services/member.service';
import { PhonePipe } from 'src/app/shared/pipe/phone.pipe';
import { CepPipe } from 'src/app/shared/pipe/cep.pipe';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { MemberShipCardsDependentsModel } from 'src/app/shared/models/memberModel';
import { TermsService } from 'src/app/core/services/terms.service';
import { PaymentInvoiceModalComponent, PaymentInvoiceModalData } from 'src/app/shared/components/payment-invoice-modal/payment-invoice-modal.component';
import { PaymentModalComponent } from 'src/app/shared/components/payment-modal/payment-modal.component';
import { PartnerService } from 'src/app/core/services/partner.service';
import { ExperienceService } from 'src/app/core/services/experience.service';
import { fromEvent, Subscription } from 'rxjs';
import { MemberFinalScoreService } from 'src/app/core/services/member-final-score.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [AppButtonComponent, CommonModule, CustomCurrencyPipe, MatIconModule, RouterLink, MyPlanCardComponent, InvoiceTableComponent, PaymentInvoiceModalComponent, CarouselPlansComponent, PhonePipe, CepPipe, PaymentModalComponent, AppModalComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  heightLastInvoice = 0;
  user: UserModel;
  memberFinalScore: MemberFinalScoreModel;
  pattern: any;
  lastInvoice: any;
  plan: PlanDashboardTO;
  cepPipe = new CepPipe;
  memberShipCard: MemberShipCardsDependentsModel;
  memberPlanId: any;

  modalId = 'payment-modal';
  orderModalId = 'orderModal';
  PaymentType = PaymentType;
  paymentType: PaymentType;
  paymentInvoiceModalData: PaymentInvoiceModalData;
  showDashboard = false;
  canChekPix = true;

  btnUpgradeOptions = {
    buttonText: 'Fazer Upgrade',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-small px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000',
    fontSize: 12
  };

  btnInvoiceDetailsOptions = {
    buttonText: 'Detalhar Fatura',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm btn-primary fs-xsmall',
    fontSize: 12
  };

  btnUpdateDataOptions = {
    buttonText: 'Atualizar Dados',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12
  };

  btnRankDetailsOptions = {
    buttonText: 'Mais detalhes',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12
  };

  btnExperienceOptions = {
    buttonText: 'Ver mais experiências',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12
  };

  btnPartnershipsCouponsOptions = {
    buttonText: 'Ver todos cupons',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm fs-xsmall btn-primary',
    fontSize: 12,
  };

  btnRescueOptions = {
    buttonText: 'Resgatar',
    borderRadius: '25px',
    buttonSize: 'btn fs-xsmall px-2 py-1 btn-primary',
    fontSize: 12,
  };

  invoiceList: any[];
  invoice: InvoiceModel;

  nextMatch = {
    date: '21/04/2024 às 16:00',
    title: 'BRASILEIRÃO SÉRIE A | Arena BRB Mané Garrincha',
    team1: {
      name: 'Football FC',
      src: 'assets/imagens/logo.png'
    },
    team2: {
      name: 'ESTD',
      src: 'assets/imagens/logo2.png'
    },
  }

  orderModalBodyMessage = 'Você já possui um pedido de plano em andamento. Deseja continuar com o pedido ou cancelar?';
  termsModalBodyMessage: string = '';

  creditCardInfo: WritableSignal<CreditCardInfoModel[]> = signal([]);
  partners: any;
  experiences: any;

  width!: any
  // 
  resizeSubscription!: Subscription;
  //

  constructor(private userService: UserService,
    private planService: PlanService,
    private invoiceService: InvoiceService,
    private paymentDataService: PaymentDataService,
    private memberFinalScoreService: MemberFinalScoreService,
    private router: Router,
    private partnerService: PartnerService,
    private termsService: TermsService,
    private modalService: ModalService,
    private memberService: MemberService,
    private commonService: CommonService,
    private experienceService: ExperienceService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.user = this.userService.currentUserValue as UserModel;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.getMemberPublicInfo();
  }

  getMemberPublicInfo() {
    this.memberService.getMemberPublicInfo(this.user.cpf).subscribe(res => {
      this.user = res;
      if (this.user?.termsAccepted == false) {
        this.showTermsModal();
      } else {
        if (this.user?.memberPlans?.length == 0 || this.user?.memberSituation?.name === "Cancelado") {
          this.router.navigate(['/selecionar-plano']);
        } else {
          this.getExperiences();
          this.getPartners();
          this.getMemberFinalScore();

          if (this.user?.memberPlans?.length == 0) return
          this.memberService.getMemberDashboardData(this.user.id).subscribe(res => {
            this.pattern = res;
            this.memberService.getMemberShipCardDashboardData(this.pattern.id).subscribe(res => {
              this.memberShipCard = res;
            })
          })

          this.getMembePlanDashboardData();
          const creditCardArr = this.commonService.getCreditCardInfoFromLocalStorage();
          this.creditCardInfo.set(creditCardArr);
        }
      }
    });
  }

  getMembePlanDashboardData() {
    this.memberService.getMembePlanDashboardData(this.user.id).subscribe(res => {
      this.plan = res;
      this.memberPlanId = res.id;
      if (this.plan) {
        this.memberService.getMembeInvoiceDashboardData(this.plan.id).subscribe(invoices => {
          invoices.forEach(invoice => {
            // Mantém os valores nulos se refMonth ou refYear forem nulos
            const refMonth = invoice.refMonth !== null ? invoice.refMonth : null;
            const refYear = invoice.refYear !== null ? invoice.refYear : null;
            invoice.referenceMonthSort = refYear !== null && refMonth !== null ? new Date(parseInt(refYear), parseInt(refMonth) - 1) : null;
            invoice.refMonth = refMonth !== null ? refMonth.toString().padStart(2, '0') : null;
          });
          invoices.sort((a, b) => {
            if (a.referenceMonthSort && b.referenceMonthSort) {
              return new Date(b.referenceMonthSort).getTime() - new Date(a.referenceMonthSort).getTime();
            }
            return 0;
          });
          this.invoiceList = invoices;
        });
        this.memberService.getLastMembeInvoiceDashboardData(this.plan.id).subscribe(lastInvoices => {
          this.lastInvoice = lastInvoices;
        });
      }
      this.showDashboard = true;
      this.cdr.detectChanges();
    });
  }

  showOptionsPayment(): boolean {
    switch (this.lastInvoice?.invoicePaymentStatus?.statusTypeName) {
      case 'Aberto':
        return true
        break;
      case 'Vencido':
        return true
        break;
      case 'Rejeitado':
        return true
        break;
      case 'Não Autorizado':
        return true
        break;
      /* case 'Cancelado':
        return true
        break; */
      default:
        return false
        break;
    }
  }

  getExperiences() {
    this.experienceService.getExperiences().subscribe((res: any) => {
      this.experiences = res.content.slice(0, 3);
      this.experiences.forEach((experience: any) => {
        experience.src = this.commonService.getImageStr(experience.document.id);
      });
    });
  }


  getPartners() {
    this.partnerService.getAllPartners().subscribe((partners: any) => {
      this.partners = partners.content.slice(0, 3);
      this.partners.forEach((partner: any) => {
        partner.src = this.commonService.getImageStr(partner.document.id);
      });
    });

  }

  getTrendClass(): string {
    if (!this.memberFinalScore || !this.memberFinalScore.trend) {
      return '';
    }
    switch (this.memberFinalScore.trend) {
      case 'up':
        return 'bi-caret-up-fill text-success';
      case 'down':
        return 'bi-caret-down-fill text-danger';
      case 'same':
        return 'bi-dash text-warning';
      default:
        return '';
    }
  }

  getMemberFinalScore() {
    this.memberFinalScoreService.getByMemberId(this.user.id).subscribe((res: any) => {
      this.memberFinalScore = res;
    });
  }

  setHeight(event: any) {
    console.log('setHeight', event);

    this.heightLastInvoice = event
  }

  openModal(paymentType: PaymentType) {

    this.paymentInvoiceModalData = {
      invoiceId: this.lastInvoice.id,
    };
    this.paymentType = paymentType;
    setTimeout(() => {

      this.modalService.open(this.modalId);
    }, 100);
  }

  goToPartnerData() {
    this.router.navigate(['/dados-de-socio']);
  }

  goToExperiences() {
    this.router.navigate(['/experiencias']);
  }

  onModalConfirm(event: any): void {
    console.log('onModalConfirm', event);


  }


  goToInvoiceDetail(invoice: any): void {
    // TODO: Mudar approach se o invoice obj for muito grande
    this.router.navigate(['/ver-fatura'], { state: { invoice: invoice } });
  }

  rankDetail(): void {
    this.router.navigate(['/ranking-de-socios']);
  }
  goToRescue(): void {
    this.router.navigate(['/resgates']);
  }

  showAllCoupons(): void {
    this.router.navigate(['/parcerias']);
  }

  getAddress() {
    if (this?.pattern?.addresses[0]) {
      let address = this?.pattern?.addresses[0];
      return `${address?.area},  ${address?.number != null ? address?.number : 'S/N'}, ${address?.complement}, ${address?.neighborhood}, ${address?.cityName} - ${address?.stateName},  CEP: ${this.cepPipe.transform(address?.zipCode)} `
    } else {
      return '-';
    }

  }

  showTermsModal() {
    this.termsService.getTerms().subscribe((data) => {
      this.termsModalBodyMessage = data[0].content;
      this.modalService.open('termsModal');
    });

  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  onTermsModalConfirm() {
    this.memberService.setAcceptTerms(this.user.id).subscribe(res => {
    }
    );

  }

}
