<div [ngStyle]="styles">
    <ul class="actions-menu">
        <li><a *ngIf="!userBlocked()" class="dropdown-item py-2" routerLink="/dashboard" (click)="actionEventClick()"><i class="bi bi-house-fill me-2"></i> Home</a></li>
        <li><a class="dropdown-item py-2" routerLink="/dados-de-socio" (click)="actionEventClick()"><i class="bi bi-person-fill me-2"></i> Dados de Sócio</a></li>
        <li><a *ngIf="!userBlocked()" class="dropdown-item py-2" routerLink="/meu-plano" (click)="actionEventClick()"><i class="bi bi-shield-fill-check me-2"></i> Meu Plano</a></li>
        <li><a *ngIf="!userBlocked()" class="dropdown-item py-2" routerLink="/convidar-amigo" (click)="actionEventClick()"><i class="bi bi-person-plus-fill me-2"></i> Convidar amigo</a></li>
        <li><a class="dropdown-item py-2" routerLink="/alterar-senha" (click)="actionEventClick()"><i class="bi bi-key-fill me-2"></i> Alterar Senha</a></li>
        <!-- <li><a *ngIf="!userBlocked()" class="dropdown-item py-2" routerLink="/gerenciar-pagamento" (click)="actionEventClick()"><i class="bi bi-credit-card-fill me-2"></i> Gerenciar Pagamento</a></li> -->
        <li><a class="dropdown-item py-2" routerLink="/faq" (click)="actionEventClick()"><i class="bi bi-question-circle-fill me-2"></i> FAQ</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item py-2" (click)="logout()"><i class="bi bi-box-arrow-right me-2"></i> Sair</a></li>
    </ul>
</div>
