import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvoiceModel, InvoiceType } from 'src/app/shared/models/invoiceModel';
import { Page } from 'src/app/shared/models/Page';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {

  private env = environment;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getInvoices(): Observable<Page<InvoiceModel>> {
    return this.get<Page<InvoiceModel>>(`${this.env.socioApiUrl}/invoices`);
  }

  
  getInvoiceById(id: string, params: HttpParams): Observable<InvoiceModel> {
    return this.get<InvoiceModel>(`${this.env.socioApiUrl}/invoices/${id}`, {params});
  }


  getNextInvoices(memberPlanId: string): Observable<any> {
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/invoices/get-next-invoices/${memberPlanId}`);
  }

  getAntecipationRenegotiationInvoices(invoiceId: string): Observable<any[]> {
    return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/invoices/get-antecipation-invoices/${invoiceId}`);
  }

  getInvoiceTypes(): Observable<any> {
    return this.http.get<Page<InvoiceType>>(
      `${this.env.socioApiUrl}/invoice-type`,
    );
  }

  postPublicInvoice(invoice: InvoiceModel): Observable<InvoiceModel> {
    return this.post<InvoiceModel>(`${this.env.publicApiSocioUrl}v1/socio/invoices`, invoice);
  }

  createAntecipationInvoice(invoice: any): Observable<any> {
    return this.post<any>(`${this.env.publicApiSocioUrl}v1/socio/invoices/create-antecipation`, invoice);
  }

  paymentInvoice(idInvoice : string, invoice: any): Observable<any> {
    return this.put<any>(`${this.env.publicApiSocioUrl}v1/socio/charger/invoice/${idInvoice}`, invoice);
  }

  checkInvoicePaid(invoiceId: string): Observable<boolean> {
    return this.get<boolean>(`${this.env.publicApiSocioUrl}v1/socio/invoices/check-invoice-paid/${invoiceId}`);
  }

  finishAntecipation(invoiceId: string, status : boolean): Observable<any> {
    return this.post<any>(`${this.env.publicApiSocioUrl}v1/socio/invoices/finish-antecipation/${invoiceId}/${status}`, {});
  }


}
