import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { SectionMainService } from "../../../../../core/services/section-main.service";
import { CommonService } from 'src/app/core/services/common.service';
import { MemberService } from 'src/app/core/services/member.service';
import { StyleContentMainSection } from 'src/app/shared/models/styleContent';

@Component({
  selector: 'app-section-main',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, RouterLink],
  templateUrl: './section-main.component.html',
  styleUrls: ['./section-main.component.scss']
})
export class SectionMainComponent {

  styles: { [key: string]: string };
  isMobile = window.innerWidth < 768;
  sectionMain: StyleContentMainSection;

  buttonOptions:any = {
    buttonText: 'Quero ser sócio',
    borderRadius: '25px',
    buttonSize: 'btn btn-lg px-4 py-2'
  };
  componentData: any = {}

  constructor(
    private sectionMainService: SectionMainService,
    private commonService: CommonService,
    private memberService: MemberService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
    this.setStyles(this.sectionMain);
  }

  ngOnInit(): void {
    this.getAllMembersNumber();
    this.setMainSectionStyles();
  }

  private setMainSectionStyles(): void {
    this.sectionMainService.getSectionMain().subscribe({
      next: (res: StyleContentMainSection) => {
        if (res?.id) {
          this.sectionMain = res;

          this.setStyles(this.sectionMain);

          const prexfix = this.sectionMain.titleText.split('{quantidade_socios}')[0];
          const sufix = this.sectionMain.titleText.split('{quantidade_socios}')[1];

          this.componentData.prefixo = prexfix;
          this.componentData.sufixo = sufix;
          this.componentData.subtitle = this.sectionMain.textSub;
          this.componentData.specialCharacter = this.sectionMain.specialCharacter;
        }
        
        this.setButtonOptions(this.sectionMain);
      }
    });
  }

  private setButtonOptions(sectionMain: StyleContentMainSection): void {
    if (!sectionMain) {
      return;
    }    

    if (sectionMain?.id) {
      this.buttonOptions.primaryColor = this.sectionMain.buttonColor;
      this.buttonOptions.buttonTextColor = this.sectionMain.buttonTextColor;
      this.buttonOptions.buttonBorderColor = this.sectionMain.buttonBorderColor  || 'none';
      this.buttonOptions.buttonBorderWidth = this.sectionMain.hasButtonBorder ? '1px' : 'none';
      this.buttonOptions.btnBorderHover= this.sectionMain.buttonBorderWhenHovering,
      this.buttonOptions.btnBorderHoverColor= this.sectionMain.buttonBorderColorWhenHovering,
      this.buttonOptions.btnTextHoverColor= this.sectionMain.buttonTextColorWhenHovering,
      this.buttonOptions.btnHoverColor= this.sectionMain.buttonColorWhenHovering
    }
    this.buttonOptions.buttonText =  'Quero ser sócio';
    this.buttonOptions.borderRadius = '25px';
    this.buttonOptions.buttonBorderWidth = this.sectionMain.hasButtonBorder ? '1px' : 'none';
    this.buttonOptions.buttonSize = 'btn btn-lg px-4 py-2';   
  }

  private setStyles(sectionMain: StyleContentMainSection) {
    if (!sectionMain) {
      return;
    }

    this.styles = {
      '--show-section': sectionMain.showSection ? 'flex' : 'none',
      '--background-color': sectionMain.backgroundIsImage ? 'none':sectionMain.backgroundColor,
      '--counter-color': sectionMain.socialAccountantColor,
      '--counter-font-size': this.commonService.getRemFontSize(sectionMain.counterSize, this.isMobile),
      '--title-color': sectionMain.titleColor,
      '--title-font-size': this.commonService.getRemFontSize(sectionMain.titleSize, this.isMobile),
      '--special-character-color': sectionMain.specialCharacterColor,
      '--special-character-size': this.commonService.getRemFontSize(sectionMain.specialCharacterSize, this.isMobile),
      '--subtitle-color': sectionMain.subtitleColor,
      '--subtitle-font-size': this.commonService.getRemFontSize(sectionMain.subtitleSize, this.isMobile),
      '--background-image': sectionMain.backgroundIsImage ? `url(${this.getImageUrl(sectionMain)})`: 'none',
    }
  }

  getImageUrl(sectionMain: any) {
    if (sectionMain.defaultStyle) {
      return sectionMain.backgroundImageUrl;
    }
    return this.commonService.getImageStr(sectionMain.document.id);
  }

  getAllMembersNumber(): void {
    this.memberService.getAmountMembersBySituation().subscribe({
      next: (res: any) => {
        this.componentData.highlight = res;
      }
    });
  }
}
