import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { SectionMainComponent } from './sections/section-main/section-main.component';
import { sectionPlanosComponente } from './sections/section-planos/section-planos.component';
import { SectionTabelaBeneficiosComponent } from './sections/section-tabela-beneficios/section-tabela-beneficios.component';
import { SectionProximoConfrontoComponent } from './sections/section-proximo-confronto/section-proximo-confronto.component';
import { SectionCardBeneficiosComponent } from './sections/section-card-beneficios/section-card-beneficios.component';
import { SectionConteudoExclusivoComponent } from './sections/section-conteudo-exclusivo/section-conteudo-exclusivo.component';
import { SectionSimpleCardComponent } from './sections/section-simple-card/section-simple-card.component';
import { SectionVideoComponent } from './sections/section-video/section-video.component';
import { SectionCarouselBeneficiosComponent } from './sections/section-carousel-beneficios/section-carousel-beneficios.component';
import { SectionRankingComponent } from './sections/section-ranking/section-ranking.component';
import { SectionFaqComponent } from './sections/section-faq/section-faq.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    SectionMainComponent,
    sectionPlanosComponente,
    SectionTabelaBeneficiosComponent,
    SectionProximoConfrontoComponent,
    SectionCardBeneficiosComponent,
    SectionConteudoExclusivoComponent,
    SectionSimpleCardComponent,
    SectionVideoComponent,
    SectionCarouselBeneficiosComponent,
    SectionRankingComponent,
    SectionFaqComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  enableWhatsappButton = localStorage.getItem('enableWhatsappButton') === 'true';
  whatsappLink: SafeUrl;

  constructor(private renderer: Renderer2, private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.changeParentContainerClass();
    this.setWhatsappLink();
  }

  ngOnDestroy() {
    this.restoreParentContainerClass();
  }

  private setWhatsappLink() {
    const link = localStorage.getItem('whatsappLink');
    if (link) {
      this.whatsappLink = this.sanitizer.bypassSecurityTrustUrl(link.replace(/"/g, ''));
    }
  }

  // Tela full screen, sem o container
  private changeParentContainerClass() {
    const parent = this.el.nativeElement.parentElement;
    if (parent) {
      this.renderer.removeClass(parent, 'container');
    }
  }

  private restoreParentContainerClass() {
    const parent = this.el.nativeElement.parentElement;
    if (parent) {
      this.renderer.addClass(parent, 'container');
    }
  }
}