import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { AppButtonComponent } from '../app-button/app-button.component';
import { CardsPalpites } from '../../models/cardsPalpites';

@Component({
  selector: 'app-cards-palpites',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './app-cards-palpites.component.html',
  styleUrl: './app-cards-palpites.component.scss'
})
export class AppCardsPalpitesComponent {
  labelColor: '#FFFFFF';
  data = input.required<CardsPalpites[]>();
  title = input<string>();
  cardAction = output<Array<any>>();

  teamName = localStorage.getItem('clubName');

  predictionBtn = {
    buttonText: "DÊ SEU PALPITE",
    borderRadius: "25px",
    buttonSize: "btn btn-md py-2 btn-primary",
    fontSize: 14
  };

  btnAction(teams: any) {
    this.cardAction.emit(teams);
  }

}
