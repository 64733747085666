import { Component } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Location } from '@angular/common';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MemberService } from 'src/app/core/services/member.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-edit-plan',
  standalone: true,
  imports: [AppButtonComponent, AppCheckboxComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './edit-plan.component.html',
  styleUrl: './edit-plan.component.scss'
})
export class EditPlanComponent {

  currentUser: any;
  title = 'Editar Plano';
  invoiceRecurrenceControl = new FormControl<boolean>(false);
  currentUserData : any;
  showRenewalMessage : any = true;

  btnSaveOptions = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-1 px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  btnCancelOptions = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-1 px-3 btn-secondary',
  };

  constructor(
    private location: Location,
    private memberService: MemberService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
     this.currentUserData = this.localStorageService.getItem('current-user');
    console.log(this.currentUserData.memberPlans[0].id)
    
    if (this.currentUserData) {
      if (this.currentUserData.memberPlans.length > 0) {
        const memberPlan = this.currentUserData.memberPlans[0];
        this.invoiceRecurrenceControl.setValue(memberPlan.automaticRenewal);
      } else {
        console.warn('Nenhum plano de membro encontrado.');
      }
    } else {
      console.error('Dados do usuário não encontrados no local storage.');
    }
  }

  onCheckboxChange() {
    this.showRenewalMessage = this.invoiceRecurrenceControl.value;
  }

  save() {
    const automaticRenewal = this.invoiceRecurrenceControl.value ?? false; 
    const memberId = this.currentUserData?.memberPlans[0]?.id

    this.memberService.updateAutomaticRenewal(memberId, automaticRenewal).subscribe({
      next: (response) => {
        console.log('Atualização bem-sucedida:', response);
      },
      error: (error) => {
        console.error('Erro ao atualizar:', error);
      }
    });
  }

  cancel() {
    this.location.back();
  }
}
