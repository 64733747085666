import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { Location } from '@angular/common';
import { DownloadService } from 'src/app/core/services/download.service';
import { TransparencyService } from 'src/app/core/services/transparency.service';
import { BalancesModel } from 'src/app/shared/models/balancesModel';
import { CommonService } from 'src/app/core/services/common.service';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-transparencia',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    AppInputComponent,
    ReactiveFormsModule,
    ChartModule,
  ],
  templateUrl: './transparencia.component.html',
  styleUrl: './transparencia.component.scss',
})
export class TransparenciaComponent implements OnInit  {
  @ViewChild('buttons') buttons!: ElementRef;
  labelColor = 'white';
  activeItem: any;
  targetId: string = '';
  tooltipData: string = '';
  showTooltip: boolean = false;
  tooltipLeft: number = 0;
  tooltipTop: number = 0;
  citiesRanking: any;
  neighborhoodsRanking: any;
  statesRanking: any;
  statesRankingMaps: any[] = [];
  dataFormaPagamento = {
    labels: []as any[],
    datasets: []as any[],
  } as any;
  dataSexo = {
    labels: []as any[],
    datasets: []as any[],
  } as any;
  dataFaixaEtaria = {
    labels: []as any[],
    datasets: []as any[],
  } as any;
  dataPlano = {
    labels: []as any[],
    datasets: []as any[],
  } as any;
  options: any;
  optionsBar: any;
  filesData: BalancesModel[] = [];
  groupedFilesData: { [year: number]: BalancesModel[] } = {};
  @ViewChild('mapContainer') mapContainer: ElementRef;
  primaryColor = localStorage.getItem('primaryColor') || '';
  secondaryColor = localStorage.getItem('secondaryColor') || '';
  style = {
    '--primary-color': this.primaryColor,
    '--primary-color-90': this.coloLighten(this.primaryColor, 90),
    '--secondary-color': this.secondaryColor,
    '--secondary-color-90': this.coloLighten(this.secondaryColor, 90),
  };
  totalSocio: number = 0;

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };
  baseTorcedoresOptions = {
    buttonText: 'Base de Torcedores',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };
  financeiroOptions = {
    buttonText: 'Financeiro',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };
  
  styleButtons = "";
  

  // 
  resizeSubscription!: Subscription;
  //
  constructor(
    private downloadService: DownloadService,
    private transparencyService: TransparencyService,
    private commonService: CommonService,
    private location: Location
  ) { 
    this.getStatesDatas();
    this.getCitiesDatas();
    this.getNeighborhoodsDatas();
    this.getPaymentMethodDatas(); 
    this.getGendersDatas();
    this.getAgeGroupsDatas();
    this.getPlansDatas();
    setTimeout(() => {
      this.styleButtonsDynamic();
    }, 100);
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => { 
      this.styleButtonsDynamic();
    });
  }
  

  styleButtonsDynamic():void {
    const buttonsWidth = this.buttons.nativeElement.offsetWidth;
    const newWidth = (buttonsWidth / 2) - 5;
    const newFont = buttonsWidth <= 500 ? '10px' : '16px';
    this.styleButtons = `width: ${newWidth}px; height: 42px;border-radius: 25px;transition: 0.5s;font-weight: 700;text-align: center;font-size: ${newFont};margin-top: 5px`;
  }

  private getPlansDatas() {
    this.transparencyService.getPlansData().subscribe((res: any) => {
      const arrayOfPlans = Object.keys(res).map((key) => ({
        plans: key,
        quantity: res[key],
      }));

      let totalQuantity = arrayOfPlans.reduce(
        (soma, plans) => soma + plans.quantity,
        0
      );

      const plansWithPercentage = arrayOfPlans.map((plans) => {
        return {
          plans: plans.plans,
          quantity: plans.quantity,
          percentage: ((plans.quantity / totalQuantity) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((plans.quantity / totalQuantity) * 100).toFixed(
            2
          ),
        };
      });

      let labels = [] as any[];
      let datasets = [
        {
          data: [] as Number[],
          backgroundColor: [] as String[],
        },
      ];
      plansWithPercentage.forEach((item: any) => {
        labels.push(`${item.plans} (${item.percentage}%)`);
        datasets[0].data.push(item.percentageCalc);
        datasets[0].backgroundColor.push(this.primaryColor);
      });

      this.dataPlano = {
        labels: labels,
        datasets: datasets,
      };
    });
  }

  private getAgeGroupsDatas() {
    this.transparencyService.getAgeGroupsData().subscribe((res: any) => {
      const arrayOfGroupAge = Object.keys(res).map((key) => {
        let group;
        switch (key) {
          case 'Mais de 65':
            group = '65+';
            break;
          case 'Menos de 18':
            group = '<18';
            break;
          default:
            group = key;
        }
        return {
          group,
          quantity: res[key],
        };
      });

      let totalQuantity = arrayOfGroupAge.reduce(
        (soma, group) => soma + group.quantity,
        0
      );

      const genderWithPercentage = arrayOfGroupAge.map((group) => {
        return {
          group: group.group,
          quantity: group.quantity,
          percentage: ((group.quantity / totalQuantity) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((group.quantity / totalQuantity) * 100).toFixed(
            2
          ),
        };
      });

      let labels = [] as any[];
      let datasets = [
        {
          data: [] as Number[],
          backgroundColor: [] as String[],
        },
      ];
      genderWithPercentage.forEach((item: any) => {
        labels.push(`${item.group} (${item.percentage}%)`);
        datasets[0].data.push(item.percentageCalc);
        datasets[0].backgroundColor.push(this.primaryColor);
      });

      this.dataFaixaEtaria = {
        labels: labels,
        datasets: datasets,
      };

      const index = this.dataFaixaEtaria.labels.findIndex((item: any) => item.startsWith('65+'));
      if (index !== -1) {
        const [item] = this.dataFaixaEtaria.labels.splice(index, 1);
        this.dataFaixaEtaria.labels.push(item);
        const [itemData] = this.dataFaixaEtaria.datasets[0].data.splice(index, 1);
        this.dataFaixaEtaria.datasets[0].data.push(itemData);
      }
    });
  }

  private getGendersDatas() {
    this.transparencyService.getGenderData().subscribe((res: any) => {
      const arrayOfGender = Object.keys(res).map((key) => {
        let gender;
        switch (key) {
          case 'F':
            gender = 'Feminino';
            break;
          case 'M':
            gender = 'Masculino';
            break;
          case 'NI':
            gender = 'Não Informado';
            break;
          case 'null':
            gender = 'Não Preenchido';
            break;
          default:
            gender = key;
        }
        return {
          gender,
          quantity: res[key],
        };
      });

      let totalQuantity = arrayOfGender.reduce(
        (soma, gender) => soma + gender.quantity,
        0
      );

      const genderWithPercentage = arrayOfGender.map((gender) => {
        return {
          gender: gender.gender,
          quantity: gender.quantity,
          percentage: ((gender.quantity / totalQuantity) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((gender.quantity / totalQuantity) * 100).toFixed(
            2
          ),
        };
      });

      let labels = [] as any[];
      let datasets = [
        {
          data: [] as Number[],
          backgroundColor: [] as String[],
        },
      ];
      let supportColor = 0;
      genderWithPercentage.forEach((item: any) => {
        labels.push(`${item.gender} (${item.percentage}%)`);
        datasets[0].data.push(item.percentageCalc);
        datasets[0].backgroundColor.push(this.coloLighten(this.primaryColor, supportColor));
        supportColor += 30;
      });

      this.dataSexo = {
        labels: labels,
        datasets: datasets,
      };
    });
  }

  private getPaymentMethodDatas() {
    this.transparencyService.getPaymentMethodData().subscribe((res: any) => {
      const arrayOfPaymentMethod = Object.keys(res).map((key) => ({
        method: key,
        quantity: res[key],
      }));

      let totalQuantity = arrayOfPaymentMethod.reduce(
        (soma, method) => soma + method.quantity,
        0
      );

      const paymentMethodWithPercentage = arrayOfPaymentMethod.map((method) => {
        return {
          method: method.method,
          quantity: method.quantity,
          percentage: ((method.quantity / totalQuantity) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((method.quantity / totalQuantity) * 100).toFixed(
            2
          ),
        };
      });

      let labels = [] as any[];
      let datasets = [
        {
          data: [] as Number[],
          backgroundColor: [] as String[],
        },
      ];
      let supportColor = 0;
      paymentMethodWithPercentage.forEach((item: any) => {
        labels.push(`${item.method} (${item.percentage}%)`);
        datasets[0].data.push(item.percentageCalc);
        datasets[0].backgroundColor.push(this.coloLighten(this.primaryColor, supportColor));
        supportColor += 30;
      });

      this.dataFormaPagamento = {
        labels: labels,
        datasets: datasets,
      };
    });
  }

  private getCitiesDatas() {
    this.transparencyService.getCitiesData().subscribe((res: any) => {
      const arrayOfCities = Object.keys(res).map((key) => ({
        city: key,
        quantity: res[key],
      }));

      this.totalSocio = arrayOfCities.reduce(
        (soma, estado) => soma + estado.quantity,
        0
      );

      const percentagePerCities = arrayOfCities.map((estado) => {
        return {
          city: estado.city,
          quantity: estado.quantity,
          percentage: ((estado.quantity / this.totalSocio) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((estado.quantity / this.totalSocio) * 100).toFixed(
            2
          ),
        };
      });

      const orderByPercentage = percentagePerCities.sort(
        (a, b) => Number(b.percentageCalc) - Number(a.percentageCalc)
      );
      orderByPercentage.forEach((estado: any, index) => {
        estado.position = index + 1;
      });
      this.citiesRanking = orderByPercentage;
    });
  }

  private getNeighborhoodsDatas() {
    this.transparencyService.getNeighborhoodsData().subscribe((res: any) => {
      const arrayOfNeighborhoods = Object.keys(res).map((key) => ({
        neighborhood: key,
        quantity: res[key],
      }));

      this.totalSocio = arrayOfNeighborhoods.reduce(
        (soma, neighborhood) => soma + neighborhood.quantity,
        0
      );

      const percentagePerNeighborhoods = arrayOfNeighborhoods.map((estado) => {
        return {
          neighborhood: estado.neighborhood,
          quantity: estado.quantity,
          percentage: ((estado.quantity / this.totalSocio) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((estado.quantity / this.totalSocio) * 100).toFixed(
            2
          ),
        };
      });

      const orderByPercentage = percentagePerNeighborhoods.sort(
        (a, b) => Number(b.percentageCalc) - Number(a.percentageCalc)
      );
      orderByPercentage.forEach((estado: any, index) => {
        estado.position = index + 1;
      });
      this.neighborhoodsRanking = orderByPercentage;
    });
  }

  private getStatesDatas() {
    this.transparencyService.getStatesData().subscribe((res: any) => {
      const arrayOfStates = Object.keys(res).map((key) => ({
        state: key,
        quantity: res[key],
      }));

      this.totalSocio = arrayOfStates.reduce(
        (soma, estado) => soma + estado.quantity,
        0
      );

      const percentagePerState = arrayOfStates.map((estado) => {
        return {
          state: estado.state,
          quantity: estado.quantity,
          percentage: ((estado.quantity / this.totalSocio) * 100)
            .toFixed(2)
            .replace('.', ','),
          percentageCalc: ((estado.quantity / this.totalSocio) * 100).toFixed(
            2
          ),
        };
      });

      const orderByPercentage = percentagePerState.sort(
        (a, b) => Number(b.percentageCalc) - Number(a.percentageCalc)
      );
      orderByPercentage.forEach((estado: any, index) => {
        estado.position = index + 1;
      });

      this.statesRanking = orderByPercentage;
      this.statesRanking.forEach((estado: any) => {
        let labelSocio = estado.quantity > 1 ? 'sócios' : 'sócio';
        let stateMaps = {
          state: estado.state,
          text: `${estado.state}: ${estado.percentage}% - ${estado.quantity} ${labelSocio}`,
        };
        this.statesRankingMaps.push(stateMaps);
      });
    });
  }

  ngOnInit(): void {
    console.log('style', this.style);

    this.options = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            pointStyle: 'rectRounded',
            usePointStyle: true,
            padding: 20,
            font: {
              size: 16,
              family: 'Montserrat',
              lineHeight: 19.5,
              weight: 400,
            },
          },
        },
      },
      layout: {
        padding: 10,
      },
    };

    this.optionsBar = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            count: 6,
            callback: (value: number, index: any, ticks: any) => {
              return value + '%';
            },
          },
        },
      },
      font: {
        size: 16,
        family: 'Montserrat',
        lineHeight: 19.5,
        weight: 400,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    this.activeItem = 'base-de-torcedores';
    this.getFiles();
  }

  back() {
    this.location.back();
  }

  hideDiv() {
    this.showTooltip = false;
  }

  showDiv(item: string, event: MouseEvent) {
    this.showTooltip = true;

    const itemPath = event.target as SVGPathElement;
    const bbox = itemPath.getBBox();

    const svgElement = document.querySelector('.svg-container') as HTMLElement;
    const svgRect = svgElement.getBoundingClientRect();

    const pathRect = itemPath.getBoundingClientRect();

    this.tooltipLeft = pathRect.left - svgRect.left + bbox.width / 2;
    this.tooltipTop = pathRect.top - svgRect.top + bbox.height / 2;
    this.tooltipData = this.statesRankingMaps.filter(
      (a: any) => a.state == item
    )[0]?.text;
  }

  downloadPDF(file: any) {
    const url = this.commonService.getImageStr(file);
    console.log('url', url);

    this.downloadService.downloadPDF(url).subscribe(
      (blob: Blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'documento.pdf';
        anchor.click();
      },
      (error) => {
        console.error('Erro ao baixar o PDF', error);
      }
    );
  }

  getFiles(): void {
    this.transparencyService.getFilesData().subscribe({
      next: (response) => {
        this.filesData = response.content;
        this.groupFilesByYear(); // Agrupa após receber os dados
      },
      error: (error) => {
        console.error('Error loading data:', error);
      },
    });
  }

  groupFilesByYear(): void {
    this.groupedFilesData = this.filesData.reduce((acc, file) => {
      if (!acc[file.year]) {
        acc[file.year] = [];
      }
      acc[file.year].push(file);
      return acc;
    }, {} as { [year: number]: BalancesModel[] });
  }

  coloLighten(color: string, amount: number): any {
    let rgb = color.match(/\w\w/g);
    if (rgb) {
      let [r, g, b] = rgb.map((c) =>
        Math.max(0, Math.min(255, parseInt(c, 16) + amount))
      );
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    } else {
      return color;
    }
  }
}
